import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import CalculatorScreen from './screens/CalculatorScreen'

const App = () => {


  return (
    <div>
      <Header />
      <main>
        <Container>
          <br />
          <CalculatorScreen />

        </Container>
        
      </main>
      <Footer />
    </div>
  )
}

export default App
