import React, { useState } from 'react';
import { Row, Col, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from 'axios'

const CalculatorScreen = () => {

  const [complexity, setComplexity] = useState('100');
  const [applicationType, setApplicationType] = useState('CMS uden integrationer');
  const [coveragePeriod, setcoveragePeriod] = useState('Standarddækning 9-17, hverdage');
  const [updateAgreement, setUpdateAgreement] = useState('Ingen');
  const [cat1, setCat1] = useState('3 timer');
  const [cat2, setCat2] = useState('Ingen');
  const [cat3, setCat3] = useState('Ingen');
  const [hosting, setHosting] = useState('Kunden hoster selv');
  const [extraServer, setExtraServer] = useState(false); 
  const [highTrafic, setHighTrafic] = useState(false); 
  const [cloudflarePro, setCloudflarePro] = useState(false);


  const [results, setResults] = useState([]);



    const calculatePriceHandler = async () => {
      const calculationInput = {
        complexity,
        applicationType,
        updateAgreement,
        coveragePeriod,
        cat1,
        cat2,
        cat3, 
        hosting,
        extraServer,
        highTrafic,
        cloudflarePro
      }

      const { data } = await axios.post('/calculate', calculationInput)
      console.log(data)
      let arrResults = []
      arrResults = results.slice()
      arrResults.unshift(data)
      setResults(arrResults)
      console.log(arrResults)
    }

  return (

    <>
      <Row>
      <Col sm={12} md={8} lg={8} xl={8}>
      <p>Her kan du beregne pirser på serviceaftale og hosting i Eksponent.</p>  
      <strong>Applikationstype:</strong>
      <select className="form-control" onChange={event => setApplicationType(event.target.value)}>
      <option value="CMS uden integrationer" defaultValue>CMS uden integrationer</option>
      <option value="CMS med integrationer">CMS med integrationer</option>
      <option value="Selvbetjeningsløsning">Selvbetjeningsløsning</option>
      <option value="Applikation">Applikation</option>
      <option value="Special-applikation">Special-applikation</option>
      <option value="Udviklingsinfrastruktur">Udviklingsinfrastruktur</option>
      
  </select>
      <br/>
      <strong>Applikationens kompleksitet: {complexity} %</strong>

    <input type="range" min="50" max="200" step="10" defaultValue="100" id="myRange" onChange={event => setComplexity(event.target.value)}/>

    <br /> 
    <br />
    <strong>Reaktionstider:</strong>
      <Row>
        <Col>
        Kategori 1 
          <fieldset id="cat1" onChange={event => setCat1(event.target.value)}>
            <input type="radio" defaultChecked={cat1 === 'Ingen'} value="Ingen" name="cat1"/> Ingen<br />
            <input type="radio" defaultChecked={cat1 === '1 time'} value="1 time"  name="cat1"/> 1 time<br />
            <input type="radio" defaultChecked={cat1 === '2 timer'} value="2 timer" name="cat1"/> 2 timer<br />
            <input type="radio" defaultChecked={cat1 === '3 timer'} value="3 timer"  name="cat1"/> 3 timer<br />
            <input type="radio" defaultChecked={cat1 === '6 timer'} value="6 timer" name="cat1"/> 6 timer<br />
            <input type="radio" defaultChecked={cat1 === '12 timer'} value="12 timer" name="cat1"/> 12 timer<br /> 
            <input type="radio" defaultChecked={cat1 === '24 timer'} value="24 timer" name="cat1"/> 24 timer<br />
          </fieldset>
        </Col>
        <Col>
          Kategori 2
          <fieldset id="cat2" onChange={event => setCat2(event.target.value)}>
            <input type="radio" defaultChecked={cat2 === 'Ingen'} value="Ingen" name="cat2"/> Ingen<br />
            <input type="radio" defaultChecked={cat2 === '1 time'} value="1 time"  name="cat2"/> 1 time<br />
            <input type="radio" defaultChecked={cat2 === '2 timer'} value="2 timer" name="cat2"/> 2 timer<br />
            <input type="radio" defaultChecked={cat2 === '3 timer'} value="3 timer"  name="cat2"/> 3 timer<br />
            <input type="radio" defaultChecked={cat2 === '6 timer'} value="6 timer" name="cat2"/> 6 timer<br />
            <input type="radio" defaultChecked={cat2 === '12 timer'} value="12 timer" name="cat2"/> 12 timer<br /> 
            <input type="radio" defaultChecked={cat2 === '24 timer'} value="24 timer" name="cat2"/> 24 timer<br />
          </fieldset>
        </Col>
        <Col>  
          Kategori 3
          <fieldset id="cat3" onChange={event => setCat3(event.target.value)}>
            <input type="radio" defaultChecked={cat3 === 'Ingen'} value="Ingen" name="cat3"/> Ingen<br />
            <input type="radio" defaultChecked={cat3 === '1 time'} value="1 time"  name="cat3"/> 1 time<br />
            <input type="radio" defaultChecked={cat3 === '2 timer'} value="2 timer" name="cat3"/> 2 timer<br />
            <input type="radio" defaultChecked={cat3 === '3 timer'} value="3 timer"  name="cat3"/> 3 timer<br />
            <input type="radio" defaultChecked={cat3 === '6 timer'} value="6 timer" name="cat3"/> 6 timer<br />
            <input type="radio" defaultChecked={cat3 === '12 timer'} value="12 timer" name="cat3"/> 12 timer<br /> 
            <input type="radio" defaultChecked={cat3 === '24 timer'} value="24 timer" name="cat3"/> 24 timer<br />
          </fieldset>
        </Col>
      </Row>
    <br />
    <strong>Dækningsperiode</strong>    
    <select className="form-control" onChange={event => setcoveragePeriod(event.target.value)}>
      <option value="Standarddækning 9-17, hverdage" defaultValue>Standarddækning 9-17, hverdage</option>
      <option value="Udvidet 1: 7-22, hverdage">Udvidet 1: 7-22, hverdage</option>
      <option value="Udvidet 2: 7-22, inkl. weekend">Udvidet 2: 7-22, inkl. weekend</option>
      <option value="Udvidet 3: 24/7">Udvidet 3: 24/7</option>
    </select>
    <br />
    <strong>CMS-opdateringer til fast pris i serviceaftale:</strong>    
    <select className="form-control" onChange={event => setUpdateAgreement(event.target.value)}>
      <option value="Ingen" defaultValue>Ingen</option>
      <option value="Umbraco, kun sikkerhed (EOL-versioner)">Umbraco, kun sikkerhed (EOL-versioner)</option>
      <option value="Umbraco, sikkerhed og features">Umbraco, sikkerhed og features</option>
      <option value="Drupal">Drupal</option>
    </select>
    <br />
    <strong>Hosting:</strong>
    <select className="form-control" onChange={event => setHosting(event.target.value)}>
      <option value="Kunden hoster selv" defaultValue>Kunden hoster selv</option>
      <option value="Eksponent hoster (europæisk datacenter)">Eksponent hoster (europæisk datacenter)</option>
    </select>
    <br />

    <input type="checkbox" onChange={event => setExtraServer(event.target.checked)}/> Ekstra webserver instans &nbsp;&nbsp;&nbsp;&nbsp;
    <input type="checkbox" onChange={event => setHighTrafic(event.target.checked)}/> Ekstra høj trafik (20.000+ dagligt)&nbsp;&nbsp;&nbsp;&nbsp;
    <input type="checkbox" onChange={event => setCloudflarePro(event.target.checked)}/> Benyt Cloudflare Pro foran 

        </Col>

        <Col  sm={12} md={4} lg={4} xl={4}>
      <Button variant="primary" size="lg" onClick={calculatePriceHandler}>
            Beregn pris
    </Button>
         
    
      {
        (results.length > 0) ?
          results.map((result, index) => (        
          <Card key={index} className='my-3 p-3 rounded'>
            <div><strong>Pris i alt pr måned</strong><br />
              {Intl.NumberFormat('da', {style: 'currency', currency: 'DKK'}).format(result.price)}<br />
              <hr />
            </div>
            <div>
              <strong>Pris for SLA pr måned</strong><br />
              {Intl.NumberFormat('da', {style: 'currency', currency: 'DKK'}).format(result.SLACalculatedPrice)}<br />
              <small>
                <strong>Beredskab:</strong> {result.availability}<br />
                <strong>Inkluderet videnoverdragelse:</strong> {result.knowledgeTransfer}<br />
                    <strong>Udviklingsmiljøer:</strong> {result.developmentEnvironments}<br />
                <strong>Test- og demomiljø:</strong> {result.testServer}<br />
                <strong>Databehandler aftale og kontroller:</strong> {result.gdprAgreement}<br />
                <strong>Type:</strong> {result.applicationType}<br />
                <strong>Kompleksitet:</strong> {result.complexity}<br />
                <strong>Dækningsperiode:</strong> {result.coveragePeriod}<br />
                <strong>Opdateringer:</strong> {result.updateAgreement}<br />
                <strong>Reaktionstider:</strong><br/>
                Kat. 1: {result.cat1}, Kat. 2: {result.cat2}, Kat. 3: {result.cat3}<br /><br />
              </small>
            </div>
            <div>
              <strong>Hosting pr måned</strong><br />
              {Intl.NumberFormat('da', {style: 'currency', currency: 'DKK'}).format(result.hostingCalculatedPrice)}<br />
              <small>{hosting}</small>
            </div>
            <hr />

              <small>
              
              <strong>BeregningsId:</strong> {result.logId}<br />
              <strong>Beregnet d.</strong> {result.calculationTime}
              </small>
          </Card>
          ))
              :
            <Card className='my-3 p-3 rounded'>
                <div>Beregninger kan kun foretages med Eksponent ip-adresse</div>
            </Card>
      }
               
      </Col>
    </Row>    
  </>
  )
}

export default CalculatorScreen
